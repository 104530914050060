<template>
  <div class="promotion-impressions"></div>
</template>

<script>
import { getPath, getLinkInPath } from '@/assets/js/helper/dom'

export default {
  name: 'PromotionImpressions',
  data() {
    return {
      initialized: false,
      gtmPromotions: []
    }
  },
  methods: {
    delegateClick(e) {
      var target = e.target

      // Find the closest element with a data-promo-id attribute
      var promoId = target.dataset.promoId
      while (promoId === undefined && target) {
        target = target.parentElement
        promoId = target.dataset.promoId
      }

      const promoName = target.dataset.promoName
      const promoCreative = target.dataset.promoCreative
      const promoPosition = target.dataset.promoPosition

      if (promoId && promoName) {
        const link = getLinkInPath(getPath(e.target))

        if (link && this.$store.getters['gtm/isAvailable']) {
          e.preventDefault()

          this.$store.dispatch('gtm/promotionClick', {
            promotion: {
              id: promoId,
              name: promoName,
              creative: promoCreative,
              position: promoPosition
            },
            callback: () => {
              link.dispatchEvent(new MouseEvent('click', { ctrlKey: e.ctrlKey, shiftKey: e.shiftKey, altKey: e.altKey }))
            }
          })
        }
      }
    },
    registerPromotion(promotion) {
      this.gtmPromotions.push(promotion)
    },
    gtm() {
      if (!this.initialized) {
        this.initialized = true
        this.$nextTick(() => {
          this.$store.dispatch('gtm/promotionImpressions', {
            promotions: this.gtmPromotions
          })
        })
      }
    },
    attachEventListeners() {
      const elements = document.querySelectorAll('[data-promo-id]')
      elements.forEach((element) => {
        element.addEventListener('click', this.delegateClick)
      })
    }
  },
  created() {
    this.$root.$on('gtm:registration', this.registerPromotion)
  },
  mounted() {
    this.$nextTick(() => {
      // Use setTimeout to ensure all elements (especially slider-slides) are rendered
      setTimeout(() => {
        this.attachEventListeners()
      }, 1000)
    })
  },
  beforeDestroy() {
    this.destroy = true

    const elements = document.querySelectorAll('[data-promo-id]')

    elements.forEach((element) => {
      element.removeEventListener('click', this.delegateClick)
    })
  },
  watch: {
    gtmPromotions() {
      this.gtm()
    }
  }
}
</script>
