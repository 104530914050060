<template>
  <div v-if="false"></div>
</template>

<script>
export default {
  name: 'GTM',
  props: {
    action: {
      type: String
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  created () {
    this.$store.dispatch(`gtm/${this.action}`, this.data)
  }
}
</script>
